<template>

  <div>
    <topslogan/>
    <div class="top_head">
      <topnav :category_id="$route.query.choose" :mask=false></topnav>
    </div>
    <div class="category_list">
      <div style="width:100%;margin-bottom: 20px">
        <a-alert :message="'共找到符合条件的讲师证书 '+certificatedata.length+' 张，学生证书 '+certificateteachdata.length +' 张'" type="success" />
      </div>

      <div v-if="certificatedata.length !== 0" style="width:100%;">
        <h3>讲师证书</h3>
        <a-card v-for="item in certificatedata" :key="item.id" hoverable class="certificateinfo">
          <img
              slot="cover"
              :alt="item.course_title"
              src="https://www.psychomotricite.org/pdf/certificate.png"
          />
          <a-card-meta :title="item.course_title">
            <template slot="description">
              证书编号：{{item.certificate_code}}<br/>
              颁发日期：{{item.created_at}}
            </template>
          </a-card-meta>
        </a-card>

      </div>
      <div v-if="certificateteachdata.length !== 0" style="width:100%">
        <h3>学员证书</h3>
        <div class="certificatelist" >
          <a-card v-for="item in certificateteachdata" :key="item.id" hoverable class="certificateinfo" @click="goCertificate(item.id)">
            <img
                slot="cover"
                :alt="item.course_title"
                src="https://www.psychomotricite.org/pdf/certificate.png"

            />
            <a-card-meta :title="item.course_title">
              <template slot="description">
                证书编号：{{item.certificate_code}}<br/>
                颁发日期：{{item.created_at}}
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>

import Config from "@/config";
import topnav from "@/views/topnav";
import topslogan from "@/views/topslogan";
import bottom from "@/views/bottom";
// import Storage from "@/common/storage";

export default {
  components: {topslogan, topnav, bottom},
  name: "category_list",
  props: ['my_id', 'choose'],
  data() {
    return {

      server_url: Config.data.server_url,
      Certificateno: "",
      loading: false,
      language: localStorage.language,
      certificatedata: [],
      certificateteachdata: [],
      current: 1,

      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 100,
      },
    }
  },
  created() {
    this.Certificateno = this.$route.query.Certificateno
    this.getcertificate(1, this.pagination.pageSize, 1)
    this.getcertificate(1, this.pagination.pageSize, 2)
  },
  methods: {
    goCertificate(Certificateno) {

      this.$router.push({
        path: '/Certificateinfo',
        query: {
          Certificateno: Certificateno,
          t: new Date().getTime()
        }
      });
    },
    async getcertificate(pages, limit, type) {
      console.log("获取证书列表")
      this.certificatedata = [];
      let now = Date.now();
      let result = await this.$get("/api/customer/certificate/list", {
        params: {
          // token: Storage.token,
          pages: pages,
          limit: limit,
          type: type,
          certificate_code: this.Certificateno,
          t: now,
        }
      });

      if (result.status === true) {
        console.log("Customer", result.data.list)
        if (type === 1) {
          this.certificatedata = result.data.list
        }
        if (type === 2) {
          this.certificateteachdata = result.data.list
        }

        this.loading = false;
      }

      this.loading = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.top_head {
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  height: 112px;
  background-color: #002654;

}

.breadcrumb_bg {
  background: #F2F4F5;
  padding-top: 18px;
  height: 64px;
}

.breadcrumb {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;

}

.title {
  color: #fff;
}

.active {
  color: #fff;
  background: #CE1126;
}

.category_list {
  max-width: 1248px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  img{
    width: 800px;
    margin-right: auto;
    margin-left: auto;
  }



  .btn-red {
    color: #fff;
    background-color: #CE1126 !important;
    border-color: #CE1126 !important;
  }

  .articles_list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px;
    background-color: #F2F4F5;
    border-radius: 8px;
  }

}
.certificatelist{
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /deep/ .ant-card-cover{
    padding: 10px;
  }
  .certificateinfo{
    width: 30%;
    margin-right: 2%;
    margin-bottom:15px;
    img{
      width: 100%;
    }
  }
}
</style>